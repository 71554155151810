/* Base Styles
 * The styles that go here should be site-global and not specific to any individual components.
 */

html, body {
	overflow-x: hidden;
}

body {
	font-size: 16px;
	line-height: 1.3;
	font-family: 'Lato', sans-serif;
	font-weight: 300;
	background-color: $color-turquoise;
}

a {
	transition: all .2s ease-in-out;
}

button,
input,
textarea,
select {
	outline: none;
}