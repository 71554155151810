body > header.desktop {
	@include with-touch-nav {
		display: none;
	}
	padding-top: 15px;
	padding-bottom: 15px;
	background-color: #fff;
	
	.row {
		display: flex;
		align-items: center;
	
		nav {
			flex-grow: 1;
			text-align: right;
			
			.menu-primary-container {
				display: inline-flex;
	
				> ul > li {
					position: relative;
					display: inline-block;
					margin-right: 40px;
		
					> a {
						display: block;
						font-family: 'Playfair Display', serif;
						font-weight: 400;
						font-size: 17px;
						color: $color-grey;
						
						&:hover {
							color: #4da09a;
						}
					}
					
					&.current-menu-item,
					&.current_page_item {
						
						a {
							color: #4da09a;
						}
					}
					
					> .sub-menu {
						display: none;
					}
					
					&:hover {
						
						.sub-menu {
							display: block;
							position: absolute;
							top: 100%;
							left: 0;
							z-index: 99;
							padding: 25px 15px 10px 15px;
							text-align: center;
							background-color: #fff;
							
							li {
								
								a {
									display: block;
									font-size: 17px;
									line-height: 26px;
									color: #707171;
									
									&:hover {
										color: #4da09a;
									}
								}
							}
						}
					}
				}
			}
			
			.instagram,
			.facebook {
				
				span {
					display: none;
				}
				
				&:before {
					font-family: fontawesome;
					font-size: 17px;
					color: #4da09a;
				}
			}
			
			.instagram {
				
				&:before {
					content: "\f16d";
				}
			}
			
			.facebook {
				
				&:before {
					margin-left: 15px;
					content: "\f230";
				}
			}
		}
	}
}

body > header.mobile {
	$mobile-header-height: 4rem; // Height of header
	$nav-toggle-size: 2rem; // Size of hamburger
	$mobile-header-padding: #{($mobile-header-height - $nav-toggle-size) / 2.5};
	display: none;
	padding: $mobile-header-padding;
	width: 100%;
	height: $mobile-header-height;
	background-color: #fff;

	@include with-touch-nav {
		display: block;
	}

	// The menu toggle button
	.toggle-off-canvas {
		display: inline-block;
		font-weight: 100;
		font-size: $nav-toggle-size;
		cursor: pointer;

		&::before {
			font-family: FontAwesome;
			font-size: $nav-toggle-size;
			width: $nav-toggle-size;
			height: $nav-toggle-size;
			margin-right: 0.5em;
			vertical-align: top;
			content: "\f0c9";
		}
	}

	.logo {
		display: inline-block;
		margin: -$mobile-header-padding;
		padding: 0.5rem;
		float: right;
		
		img {
			height: $mobile-header-height - 1rem;
		}
	}
}

body > .mobile-off-canvas {
	position: fixed;
	top: 0;
	z-index: 105;
	width: 60vw;
	min-width: 280px;
	max-width: 400px;
	height: 100vh;
	color: #fff;
	background-color: #4da09a;

	transition: left 0.5s ease-in-out;
	left: -100%;

	&.active {
		left: 0;
	}

	header {
		padding: 8px;
		margin-bottom: 0;
		border-bottom: 1px solid black;
	}

	nav {
		padding: 0px 1rem;
		font-size: 1.5rem;
		
		ul {
			margin-top: 1rem;

			li {
				display: block;
				padding: 4px;
				margin-top: 1rem;
				
				a {
					color: #fff;
				}
				
				ul {
					margin-left: 2rem;
					font-size: 1.25rem;
					
					li {
						
						a {
							
						}
					}
				}
			}
		}
	}
}