// Put SCSS for the front page here
section.slideshow {
	
	@media #{$small-only} {
		background-color: #fff;
		padding-bottom: 200px;
	}
	
	// image caption blinks when slide transitions unless we do the following
	.soliloquy-viewport {
		overflow: visible !important;
	}
	
	.soliloquy-image {
		width: 100%;
	}
	
	.soliloquy-container .soliloquy-caption.soliloquy-caption-left {
		left: 0;
		right: 0;
		margin: 0 auto;
		width: 69.38rem;
		padding-top: 5rem;
		
		@media #{$small-only} {
			width: 100%;
		}
		
		@media #{$medium-only} {
			padding-top: 0rem;
		}
		
		.soliloquy-caption-inside {
			padding: 0px;
			text-shadow: none;
			background-color: transparent;
			
			p {
				position: relative;
				display: flex;
				flex-direction: column;
				justify-content: center;
				margin: 0px;
				padding: 0.625rem;
				width: 17.8125rem;
				height: 17.8125rem;
				text-align: center;
				font-size: 1.125rem;
				line-height: 1.67;
				color: #444445;
				background-color: rgba(#fff,0.85);
				border-radius: 50%;
				
				@media #{$small-only} {
					display: inline-flex;
					margin-left: auto;
					margin-right: auto;
				}
				
				&:before {
					position: absolute;
					top: 0.3125rem;
					left: 0.3125rem;
					content: "";
					width: calc(100% - 0.625rem);
					height: calc(100% - 0.625rem);
					border: solid 1px #4da09a;
					border-radius: 50%;
				}
				
				span,
				strong,
				b,
				em,
				i, {
					display: block;
					font-family: 'quilline_script_thinregular';
					font-weight: normal;
					font-size: 42px;
					line-height: 32px;
					color: #4da09a;
				}
			}
			
			.credit {
				position: absolute;
				bottom: 0;
				right: 0;
				padding: 0.3rem 0.5rem;
				font-size: 0.7rem;
				color: #333;
				background-color: rgba(255,255,255,0.7);
				
				@media #{$small-only} {
					display: none;
				}
			}
		}
	}
	
	.soliloquy-controls {
		
		.soliloquy-pager {
			
			.soliloquy-pager-item {
				
				.soliloquy-pager-link {
					background-image: none;
					border-radius: 50%;
					border: solid 2px #fff;
					background-color: #fff;
					
					&.active {
						background-color: #4da09a;
					}
				}
			}
		}
	}
}

section.services {
	padding-top: 50px;
	padding-bottom: 70px;
	text-align: center;
	background-color: #fff;
	
	h1 {
		font-family: 'quilline_script_thinregular';
		font-weight: normal;
		font-size: 40px;
		color: #4da09a;
		
		&:after {
			display: block;
			content: "";
			margin-left: auto;
			margin-right: auto;
			margin-bottom: 15px;
			width: 95px;
			height: 1px;
			background-color: #444445;
		}
	}
	
	a {
		position: relative;
		display: block;
		width: 16rem;
		height: 16rem;
		font-family: 'Playfair Display', serif;
		font-weight: 400;
		font-size: 18px;
		color: #4da09a;
		background-repeat: no-repeat;
		background-position: center center;
		background-size: cover;
		border-radius: 50%;
		
		@media #{$medium-down} {
			margin-left: auto;
			margin-right: auto;
			margin-bottom: 1rem;
		}
		
		span {
			position: absolute;
			bottom: 3.125rem;
			left: 0;
			width: 100%;
			padding: 0.625rem 0;
			display: block;
			background-color: rgba(255,255,255,0.8);
		}
		
		&.events {
			background-image: url(../images/bg-events.jpg);
		}
		
		&.design-branding {
			background-image: url(../images/bg-design-branding.jpg);
		}
		
		&.occasions {
			background-image: url(../images/bg-occasions.jpg);
		}
		
		&.experiences {
			background-image: url(../images/bg-experiences.jpg);
		}
		
		&:before {
			padding-top: 80px;
			font-family: 'quilline_script_thinregular';
			font-weight: normal;
			font-size: 1.75rem;
			content: "Find Out More";
			color: #fff;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			border-radius: inherit;
			background-color: rgba(77,160,154,0.63);
			opacity: 0;
			transition: opacity 0.33s ease-in-out;
		}
		
		&:hover {
			
			&:before {
				opacity: 1;
			}
		}
	}
}

section.start {
	padding-top: 28px;
	padding-bottom: 24px;
	background-color: $color-light-grey;
	
	.gform_wrapper {
		margin: 0px;
		padding: 0px;
		max-width: 100% !important;
		
		form {			

			.validation_error {
				display: none;
				text-align: center;
			}
			
			.gform_heading {
				display: inline-flex;
				margin: 0px;
				width: auto !important;
				
				.gform_title {
					margin: 0px;
					font-size: 30px;
					color: #fff;
					font-family: 'quilline_script_thinregular';
					font-weight: normal;
					
					@media #{$small-only} {
						margin-bottom: 1rem;
						text-align: center;
					}
				}
			}
			
			.gform_body {
				display: inline-flex;
				
				@media #{$small-only} {
					margin-bottom: 1rem;
					width: 100%;
				}
				
				.gform_fields {
					display: inline-flex;
					
					@media #{$small-only} {
						display: block;
						width: 100%;
					}
					
					.gfield {
						border-style: none;
						
						@media #{$small-only} {
							display: block;
							width: 100%;
						}
						
						.gfield_label {
							display: none;
							margin: 0px;
							
							.gfield_required {
								
							}
						}
						
						.ginput_container {
							margin: 0px;
							
							@media #{$small-only} {
								width: 100%;
							}
							
							input[type="text"],
							input[type="email"],
							textarea {
								margin-left: 1.5rem;
								padding: 10px 10px 10px 12px;
								width: 14rem;
								font-size: 18px;
								color: #444445;
								border: solid 1px #444445;
								background-color: #fff;
								
								@media #{$small-only} {
									margin-left: 0px;
									width: 100%;
								}
							}
						}
						
						&.gfield_error {
							margin: 0px;
							width: 100%;
							background: none;
							
							.ginput_container {
								max-width: 100%;
								margin: 0px 0px 0px 30px;
							
								input[type="text"],
								input[type="email"],
								textarea {
									margin: 0px;
									background-color: #FFDFE0;
								}
							}
							
							.validation_message {
								display: none;
								margin: 0px;
								padding: 0px !important;
								max-width: 100%;
							}
						}
					}
				}
			}
			
			.gform_footer {
				display: inline-flex;
				margin: 0px;
				padding: 0px;
				
				@media #{$small-only} {
					display: block;
					text-align: center;
				}
				
				button,
				.button {
					margin-left: 20px;
					padding: 12px 25px 12px 25px;
					font-size: 18px;
					color: #fff;
					text-transform: uppercase;
					background-color: #4da09a;
					border-radius: 10px;
					
					@media #{$small-only} {
						margin-left: auto;
						margin-right: auto;
					}
				}
			}
		}
	}
}

section.galleries {
	padding-top: 60px;
	padding-bottom: 100px;
	text-align: center;
	background-color: #fff;

	h1 {
		margin-bottom: 40px;
		font-family: 'quilline_script_thinregular';
		font-weight: normal;
		font-size: 40px;
		color: #4da09a;
		
		&:after {
			display: block;
			content: "";
			margin-left: auto;
			margin-right: auto;
			margin-bottom: 15px;
			width: 95px;
			height: 1px;
			background-color: #444445;
		}
	}
	
	img {
		margin-bottom: 1rem;
	}
	
	.envira-album-title {
		margin-top: 15px;
		font-family: 'Playfair Display', serif;
		font-weight: 400;
		font-size: 26px;
		color: #4da09a;
	}
	
	.envira-album-caption {
		font-size: 24px;
		color: #707171;
		text-align: center;
	}
}