// Add this class or pseudoclass to any content generated by WordPress.
// Additionally, it can be used when you want to ensure consistent styling.

// Edit + remove styles here freely.

%wysiwyg, .wysiwyg {
	h1 {
		font-size: 3rem;
	}

	h2 {
		font-size: 2.3rem;
	}

	h3 {
		font-size: 1.9rem;
	}

	h4 {
		font-size: 1.4rem;
	}

	p {
		margin: 1rem 0;
	}

	ul {
		list-style: disc;
		margin-left: 1rem;
	}

	ol {
		counter-reset: ol;

		> li {
			counter-increment: ol;

			&::before {
				content: counter(ol);
				margin-right: 0.5rem;
			}
		}
	}

	a {
		text-decoration: underline;
		color: blue;
	}

	button, a.button, input[type="button"] {
		display: inline-block;
		padding: 0.2rem;
		border: 1px solid black;
	}

	input[type="text"], input[type="tel"], input[type="email"], textarea {
		border: 1px solid black;
	}

	blockquote {
		cite {
		}
	}
}