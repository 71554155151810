.single-envira {

	.content {
		position: relative;
		
		> a {
			position: absolute;
			top: -65px;
			left: 5px;
			font-size: 0;
			text-decoration: none;
		
			&:before {
				font-family: fontawesome;
				font-size: 2rem;
				content: "\f053";
			}
		}
	}
}

section.banner {
	height: 310px;
	background-color: $color-light-grey;
	background-repeat: no-repeat;
	background-position: center center;
	background-size: cover;
}

main {
	padding-top: 55px;
	padding-bottom: 60px;
	background-color: #fff;
	
	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		color: $color-turquoise;
	}
	
	h1 {
		font-family: 'quilline_script_thinregular';
		font-weight: normal;
		font-size: 40px;
		text-align: center;
		
		&:after {
			display: block;
			content: "";
			margin-left: auto;
			margin-right: auto;
			margin-bottom: 15px;
			width: 95px;
			height: 1px;
			background-color: #444445;
		}
	}
	
	h2 {
		margin-bottom: 30px;
		font-family: 'Playfair Display', serif;
		font-weight: 400;
		font-size: 34px;
	}
	
	h3 {
		margin-bottom: 30px;
		font-family: 'quilline_script_thinregular';
		font-weight: normal;
		font-size: 30px;
	}
	
	h4 {
		margin-bottom: 30px;
		font-family: 'Playfair Display', serif;
		font-weight: 400;
		font-size: 26px;
	}
	
	h5 {
		margin-bottom: 30px;
		font-family: 'quilline_script_thinregular';
		font-weight: normal;
		font-size: 20px;
	}
	
	h6 {
		margin-bottom: 30px;
		font-family: 'Playfair Display', serif;
		font-weight: 400;
		font-size: 18px;
	}
	
	p {
		margin-bottom: 30px;
		font-size: 16px;
		line-height: 24px;
		color: #444445;
	}
	
	a {
		color: $color-turquoise !important;
		
		&:hover {
			text-decoration: none;
		}
	}
	
	ul,
	ol {
		margin-bottom: 30px;
		
		li {
			
		}
	}
	
	blockquote {
		position: relative;
		margin: 3rem 6rem 1.875rem 6rem;
		
		&:before {
			position: absolute;
			top: -4.5rem;
			left: -4rem;
			content: "“";
			font-family: 'Playfair Display', serif;
			font-size: 10rem;
			color: #4da09a;
		}
	
		p {
			color: #4da09a;
			font-size: 1.5rem;
			font-style: italic;
			line-height: 1.8rem;
			
			&:after {
				content: "”";
			}
		}
		
		cite {
			
		}
	}
	
	form {
		margin-bottom: 30px;
	}
	
	strong,
	b {
		font-weight: 700;
	}
	
	em,
	i {
		font-style: italic;
	}
	
	.round {
		border-radius: 50%;
		
		@media #{$small-only} {
			display: block;
			margin-left: auto;
			margin-right: auto;
		}
	}
	
	.bw {
		-webkit-filter: grayscale(100%);
		filter: gray;
		filter: grayscale(100%);
	}
	
	.alignleft {
		float: left;
		margin: 0px 30px 20px 0px;
	}
	
	.alignright {
		float: right;
		margin: 0px 0px 20px 30px;
	}
	
	.aligncenter {
		display: block;
		margin: 0px auto 20px auto;
	}
	
	.wp-caption {
		
		@media #{$small-only} {
			width: 100% !important;
			float: none;
			margin: 0;
		}
	}
	
	.wp-caption-text {
		margin-top: 5px;
		text-align: center;
		font-size: 2rem;
		font-family: 'quilline_script_thinregular';
		font-weight: normal;
		text-align: center;
		color: #4da09a;
	}
	
	.envira-gallery-wrap {
		
		.envira-gallery-public {
			
			.envira-gallery-item {
				
				.envira-gallery-link {
					position: relative;
					
					&:before {
						position: absolute;
						top: 0;
						left: 0;
						content: "View Photos";
						width: 100%;
						height: 100%;
						padding-top: 9rem;
						font-family: 'quilline_script_thinregular';
						font-weight: normal;
						font-size: 36px;
						color: #fff;
						text-align: center;
						background-color: rgba(77,160,154,0.63);
						opacity: 0;
						transition: opacity 0.33s ease-in-out;
					}
					
					&:hover {
						
						&:before {
							opacity: 1;
						}
					}
					
					.envira-gallery-image {
						
					}
				}
				
				.envira-album-title {
					margin-top: 15px;
					font-family: 'Playfair Display', serif;
					font-weight: 400;
					font-size: 26px;
					color: #4da09a;
				}
				
				.envira-album-caption {
					font-size: 24px;
					color: #707171;
					text-align: center;
				}
			}
		}
		
		.envira-gallery-description {
			text-align: center;
			
			iframe {
				display: block;
				margin-left: auto;
				margin-right: auto;
			}
		}
	}
}

section.sub-services {
	padding-top: 24px;
	padding-bottom: 24px;
	background-color: #e5f1f0;
	font-family: 'Playfair Display', serif;
	font-weight: 400;
	font-size: 18px;
	line-height: 24px;
	color: #4da09a;
	text-align: center;
	
	h2 {
		margin-bottom: 25px;
		font-style: italic;
	}
	
	a {
		display: inline-block;
		
		&:before {
			display: block;
			margin: 0px auto 10px auto;
			width: 67px;
			height: 67px;
			font-family: fontawesome;
			font-size: 24px;
			color: #4da09a;
			text-align: center;
			line-height: 67px;
			background-image: url(../images/bg-circle.png);
			background-repeat: no-repeat;
			background-position: center center;
		}
		
		@media #{$small-only} {
			display: block;
			margin-bottom: 1.5rem;
		}
		
		&.envelope {
			
			&:before {
				content: "\f003";
			}
		}
		
		&.leaf {
			
			&:before {
				content: "\f06c";
			}
		}
		
		&.check {
			
			&:before {
				content: "\f00c";
			}
		}
		
		&.chair {
			
			&:before {
				content: "";
				background-image: url(../images/bg-circle.png), url(../images/icon-chair.png);
			}
		}
		
		&.clip {
			
			&:before {
				content: "\f0ea";
			}
		}
	}
}

section.logos {
	padding-top: 65px;
	padding-bottom: 90px;
	text-align: center;
	background-color: #fff;
	
	h1 {
		font-family: 'quilline_script_thinregular';
		font-weight: normal;
		font-size: 40px;
		color: #4da09a;
		text-align: center;
		
		&:after {
			display: block;
			content: "";
			margin-left: auto;
			margin-right: auto;
			margin-bottom: 15px;
			width: 95px;
			height: 1px;
			background-color: #444445;
		}
	}
	
	img {
		margin: 1rem 2rem;
		width: auto;
		max-height: 60px;
		-webkit-filter: grayscale(1);
		-webkit-filter: grayscale(100%);
		filter: gray;
		filter: grayscale(100%);
	}
}

section.hello {
	padding-top: 65px;
	padding-bottom: 90px;
	text-align: center;
	background-color: #fff;
	
	h1 {
		font-family: 'quilline_script_thinregular';
		font-weight: normal;
		font-size: 40px;
		color: #4da09a;
		text-align: center;
		
		&:after {
			display: block;
			content: "";
			margin-left: auto;
			margin-right: auto;
			margin-bottom: 15px;
			width: 95px;
			height: 1px;
			background-color: #444445;
		}
	}
	
	p {
		margin-bottom: 25px;
		font-size: 18px;
		color: #444445;
	}
	
	.gform_wrapper {
		margin: 0px;
		padding: 0px;
		max-width: 100%;
		
		form {

			.validation_error {
				text-align: center;
			}
			
			.gform_body {
				
				.gform_fields {
					
					.gfield {
						border-style: none;
						
						&#field_2_7 .gfield_label {
							display: block;
							margin-bottom: 10px;
							font-weight: 700;
						}
						
						&.gfield.gf_left_half {
							margin-right: 2.5%;
						}
						
						.gfield_label {
							display: none;
							
							.gfield_required {
								
							}
						}
						
						.ginput_container {
							margin: 0px;
							
							input[type="text"],
							input[type="email"],
							input[type="tel"],
							textarea {
								margin: 0px 0px 20px 0px;
								padding: 12px 10px 12px 12px;
								width: 100%;
								font-size: 18px;
								color: #444445;
								border: solid 1px #444445;
							}
						}
						
						&.gfield_error {
							margin: 0px;
							width: 100%;
							border-style: none;
							background: none;
							
							.ginput_container {
								max-width: 100%;
								margin: 0px;
							
								input[type="text"],
								input[type="email"],
								input[type="tel"],
								textarea {
									margin: 0px;
									border: solid 1px #f00;
									background-color: #FFDFE0;
								}
							}
							
							.validation_message {
								display: none;
								margin: 0px;
								padding: 0px !important;
								max-width: 100%;
							}
						}
					}
				}
			}
			
			.gform_footer {
				margin: 0px;
				padding: 0px;
				
				button,
				.button {
					padding: 12px 25px 12px 25px;
					font-size: 18px;
					color: #fff;
					text-transform: uppercase;
					background-color: #4da09a;
					border-radius: 10px;
				}
			}
		}
	}
}